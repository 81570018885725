import { gql } from '@apollo/client';

export const citiesListQuery = gql`
  query CitiesListQuery {
    cities(filter: { scope: ALL }, limit: 70) {
      id
      name
      slug
      latitude
      longitude
    }
  }
`;

export const citiesPopularListQuery = gql`
  query CitiesPopularListQuery {
    cities(filter: { scope: POPULAR }, limit: 5) {
      id
      name
      slug
    }
  }
`;

export function regionsQuery({ cityId }) {
  const name = `RegionsQuery_${cityId}`;
  return gql`
    query ${name}($cityId: ID!) {
      regions(cityId: $cityId) {
        id
        name
        latitude
        longitude
        slug
      }
    }
  `;
}
